import React, {useState, createContext, useEffect} from 'react'

const ModalContext = createContext(false)

function ModalContextProvider({children, location}) {
  // State for telling if the modal is active or not
	const [isActive, setActive] = useState(false)
	const [dataAttr, setDataAttr] = useState()
	const [isClosed, setIsClosed] = useState(false)
	
  /* ==========================================================================
    Modal Activity Check
  ========================================================================== */
	
  useEffect(() => {
		// setActive(true)
		if(location.pathname === '/' && !isClosed){
			setDataAttr("#modal-mru-enroll")
			setActive(true)
		}
  },[location])

  useEffect(() => {
    const btn = document.querySelectorAll('[href*="#modal-"]')
    window.setTimeout(() => {
      if (btn.length) {
        btn.forEach(item => {
          item.addEventListener('click', event => {
            event.preventDefault()
            setDataAttr(event.currentTarget.attributes.href.nodeValue)
            setActive(true)
          })
        })
      }
    }, 50)
  }, [location])

	return (
		<ModalContext.Provider value={{
      isActive,
			setIsClosed: (trueOrFalse) => setIsClosed(trueOrFalse),
			setActive: (trueOrFalse) => setActive(trueOrFalse),
			setDataAttr: (string) => setDataAttr(string),
			dataAttr
    }}>
      {children}
    </ModalContext.Provider>
	)
}

export default ModalContext
export { ModalContextProvider }